import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FaBuilding, FaMoneyBillWave, FaSuitcase, FaMapMarkerAlt, FaExternalLinkAlt, FaPhoneAlt, FaUniversity } from 'react-icons/fa';
import JobsService from '../services/JobsService';
import JobApplyService from '../services/JobApplyService';
import Loading from '../components/core/Loading';
import Login from '../components/auth/Login';
import { URL, images } from '../constants';
import Register from '../components/auth/Register';

const ApplyJob = ({ isLoggedIn, setIsLoggedIn }) => {
    const { id } = useParams();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [appliedJobs, setAppliedJobs] = useState([]);
    const [isApplied, setIsApplied] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false); // État pour afficher le modal de connexion
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        mail: '',
        cv: null,
    });
    const [isRegister, setIsRegister] = useState(false); // État pour déterminer si l'utilisateur s'inscrit
    const modalRef = useRef(); // Utiliser useRef pour le modal

    useEffect(() => {
        const fetchJobData = async () => {
            try {
                const { jobData, hasApplied } = await JobsService.getJobhasAppliedById(id);
                setJob(jobData);
                setIsApplied(hasApplied);
            } catch (error) {
                setError("Erreur lors du chargement de l'offre : " + error.message);
            } finally {
                setLoading(false);
            }
        };

        // const fetchAppliedJobs = async () => {
        //     try {
        //         const appliedJobsData = await JobsService.getAppliedJobs();
        //         setAppliedJobs(appliedJobsData);
        //         setIsApplied(appliedJobsData.some((appliedJob) => appliedJob.id === parseInt(id)));
        //     } catch (error) {
        //         console.error("Erreur lors du chargement des offres postulées : ", error.message);
        //     }
        // };

        fetchJobData();
        // fetchAppliedJobs();
    }, [id]);

    const handleModalOpen = () => {
        if (isLoggedIn) {
            setShowModal(true);
        } else {
            setShowLoginModal(true); // Afficher le modal de connexion si l'utilisateur n'est pas connecté
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setShowLoginModal(false); // Fermer le modal de connexion
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, cv: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formPayload = new FormData();
        formPayload.append('first_name', formData.nom);
        formPayload.append('last_name', formData.prenom);
        formPayload.append('email', formData.mail);
        formPayload.append('cv', formData.cv);
        formPayload.append('job_id', id);

        try {
            const response = await JobApplyService.applyForJob(formPayload);
            if (response.success) {
                showAlert('Candidature soumise avec succès.', 'success'); // Utilisation de showAlert
                handleModalClose();
            }
        } catch (err) {
            showAlert('Erreur lors de l’envoi de la candidature.', 'danger'); // Utilisation de showAlert
        }
    };


    const showAlert = (message, type) => {
        const alertDiv = document.createElement('div');
        alertDiv.classList.add('alert', `alert-${type}`);
        alertDiv.innerText = message;
        alertDiv.style.position = 'fixed';
        alertDiv.style.top = '90px';
        alertDiv.style.right = '30px';
        alertDiv.style.zIndex = '9999';
        alertDiv.style.padding = '10px 20px';
        alertDiv.style.borderRadius = '5px';
        alertDiv.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
        alertDiv.style.backgroundColor = type === 'success' ? '#d4edda' : '#f8d7da';
        alertDiv.style.color = type === 'success' ? '#155724' : '#721c24';
        alertDiv.style.borderColor = type === 'success' ? '#c3e6cb' : '#f5c6cb';

        document.body.appendChild(alertDiv);

        setTimeout(() => {
            alertDiv.remove();
        }, 3000);
    };

    // Fonctions pour la gestion de l'inscription et de la connexion
    const switchToLogin = () => setIsRegister(false);
    const switchToRegister = () => setIsRegister(true);
    const toggleModal = () => handleModalClose(); // Ferme le modal

    if (loading) return <Loading />;
    if (error) return <p className="text-red-500">{error}</p>;

    return (
        <div className="apply-job-page p-4 md:p-6">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
                {/* Première Colonne (8/12) */}
                <div className="md:col-span-8 bg-white p-4 md:p-6 rounded-lg shadow-md">
                    <div className="company-info mt-4 flex items-center">
                        <img
                            src={job?.company?.logo ? `${URL}/${job.company.logo}` : images.imageNotFound}
                            alt={job?.company?.name ? `${job.company.name} Logo` : 'Image non disponible'}
                            className="w-20 h-20 md:w-24 md:h-24 object-cover rounded-md mr-4"
                        />
                        <p className="font-bold text-xl text-black">{job?.company?.name || 'Nom de la société non disponible'}</p>

                    </div>

                    <h4 className="text-xl font-bold mt-2">{job?.title || 'Titre de l\'emploi non disponible'}</h4>

                    {/* Informations sur le poste */}
                    <div className="job-info text-gray-600 text-sm md:text-base mt-4 flex flex-wrap items-center gap-x-3">
                        {job?.type_contrat?.name && (
                            <span className="bg-gray-200 px-2 py-1 rounded flex items-center space-x-1">
                                <FaSuitcase className="text-gray-500" />
                                <span>{job.type_contrat.name}</span>
                            </span>
                        )}
                        {job?.ville?.name && (
                            <span className="bg-gray-200 px-2 py-1 rounded flex items-center space-x-1">
                                <FaMapMarkerAlt className="text-gray-500" />
                                <span>{job.ville.name}</span>
                            </span>
                        )}
                        {job?.salary !== "0.00" && job?.salary && (
                            <span className="bg-gray-200 px-2 py-1 rounded flex items-center space-x-1">
                                <FaMoneyBillWave className="text-gray-500" />
                                <span>{`${job.salary} MAD`}</span>
                            </span>
                        )}
                        {job?.experience?.name && (
                            <span className="bg-gray-200 px-2 py-1 rounded flex items-center space-x-1">
                                <FaBuilding className="text-gray-500" />
                                <span>{job.experience.name} d'expérience</span>
                            </span>
                        )}
                        {job?.education?.name && (
                            <span className="bg-gray-200 px-2 py-1 rounded flex items-center space-x-1">
                                <FaUniversity className="text-gray-500" />
                                <span>{job.education.name}</span>
                            </span>
                        )}
                    </div>

                    {/* Description du poste */}
                    <div className="job-description mt-6">
                        <h3 className="text-lg font-semibold">Résumé du poste</h3>
                        <div className="mt-2 text-sm">
                            {job.description.split('\n').map((line, index, array) => {
                                const hasPeriod = line.trim().endsWith('.');
                                return (
                                    <React.Fragment key={index}>
                                        <p>{line}</p>
                                        {hasPeriod && index < array.length - 1 && <br />}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>

                    {/* Bouton de candidature */}
                    <div className="job-actions mt-6">
                        <button
                            onClick={handleModalOpen}
                            className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition ${isApplied ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={isApplied}
                        >
                            {isApplied ? 'Déjà postulé' : 'Postuler'}
                        </button>
                    </div>
                </div>

                {/* Deuxième Colonne (4/12) */}
                <div className="md:col-span-4 bg-white p-4 md:p-6 rounded-lg shadow-md">
                    <div className="p-2 rounded-t border-b-2 border-gray-200">
                        <h3 className="text-lg font-semibold text-gray-800">L'entreprise</h3>
                    </div>

                    <div className="company-info mt-4 flex items-center">
                        <img
                            src={job.company.logo ? `${URL}/${job.company.logo}` : images.imageNotFound}
                            alt={job.company.name ? `${job.company.name} Logo` : 'Image non disponible'}
                            className="w-12 h-12 md:w-16 md:h-16 object-cover rounded-md mr-4"
                        />
                        <div>
                            <p className="font-bold text-lg text-black">{job.company.name}</p>
                        </div>
                    </div>

                    <div className="mt-4 flex flex-col space-y-2 text-sm text-gray-600">
                        <span className="bg-gray-200 px-2 py-1 rounded flex items-center space-x-1">
                            <FaMapMarkerAlt className="text-gray-500" />
                            <span>{job.company.address}</span>
                        </span>
                        <span className="bg-gray-200 px-2 py-1 rounded flex items-center space-x-1">
                            <FaPhoneAlt className="text-gray-500" />
                            <span>{job.company.phone}</span>
                        </span>
                    </div>

                    <div className="mt-4 flex justify-between items-center">
                        <a
                            href={job.company.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center text-blue-600 hover:underline"
                        >
                            Voir le site
                            <FaExternalLinkAlt className="ml-1" />
                        </a>
                        <a href="" className="text-blue-600 hover:underline flex items-center">
                            Voir toutes les offres
                            <span className="bg-blue-500 text-white px-2 py-1 rounded-full ml-2">27</span>
                        </a>
                    </div>

                    <div className="p-2 rounded-t border-b-2 border-gray-200 mt-6">
                    </div>

                    <div className="mt-4">
                        <h3 className="text-lg font-semibold text-gray-800">Qui sont-ils ?</h3>
                        <span>{job.company.description}</span>
                    </div>
                </div>
            </div>


            {/* Modal for applying */}
            {showModal && isLoggedIn && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg w-full max-w-md mx-4 relative" ref={modalRef}>
                        <h2 className="text-xl font-bold mb-4">Postuler à {job.title}</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-semibold" htmlFor="nom">Nom <span className="text-red-500">*</span></label>
                                <input
                                    type="text"
                                    name="nom"
                                    id="nom"
                                    value={formData.nom}
                                    onChange={handleChange}
                                    required
                                    className="border border-gray-300 rounded-md w-full p-2"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-semibold" htmlFor="prenom">Prénom <span className="text-red-500">*</span></label>
                                <input
                                    type="text"
                                    name="prenom"
                                    id="prenom"
                                    value={formData.prenom}
                                    onChange={handleChange}
                                    required
                                    className="border border-gray-300 rounded-md w-full p-2"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-semibold" htmlFor="mail">Email <span className="text-red-500">*</span></label>
                                <input
                                    type="email"
                                    name="mail"
                                    id="mail"
                                    value={formData.mail}
                                    onChange={handleChange}
                                    required
                                    className="border border-gray-300 rounded-md w-full p-2"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-semibold" htmlFor="cv">CV (PDF) <span className="text-red-500">*</span></label>
                                <input
                                    type="file"
                                    name="cv"
                                    id="cv"
                                    accept=".pdf"
                                    onChange={handleFileChange}
                                    required
                                    className="border border-gray-300 rounded-md w-full p-2"
                                />
                            </div>
                            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition">
                                Soumettre
                            </button>
                            <button type="button" onClick={handleModalClose} className="ml-2 border border-gray-200 text-black rounded-md px-4 py-2">Annuler</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Modal for login */}
            {showLoginModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg w-full max-w-md mx-4 relative" ref={modalRef}>
                        <h2 className="text-center text-2xl font-bold mb-6">Bienvenue !</h2>

                        <div className="flex justify-center border-b-2 mb-6">
                            <button
                                className={`px-4 py-2 focus:outline-none ${!isRegister ? 'text-blue-500 font-semibold border-b-4 border-blue-400' : 'text-gray-500'}`}
                                onClick={switchToLogin}
                            >
                                Se connecter
                            </button>
                            <button
                                className={`px-4 py-2 focus:outline-none ${isRegister ? 'text-blue-500 font-semibold border-b-4 border-blue-400' : 'text-gray-500'}`}
                                onClick={switchToRegister}
                            >
                                Créer un compte
                            </button>
                        </div>

                        {isRegister ? (
                            <Register toggleModal={toggleModal} setIsLoggedIn={setIsLoggedIn} />
                        ) : (
                            <Login toggleModal={toggleModal} setIsLoggedIn={setIsLoggedIn} />
                        )}

                        {/* Partie fixe pour les recruteurs */}
                        <div className="flex items-center border-b-2 justify-center mb-4 bg-gray-200 p-2 rounded">
                            <span className="text-gray-600">Vous êtes recruteur ? </span>
                            <button
                                onClick={switchToLogin}
                                className="text-black underline ml-1 hover:text-blue-500"
                            >
                                Se connecter
                            </button>
                        </div>

                        <button onClick={toggleModal} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                            &times;
                        </button>
                    </div>
                </div>
            )}

            {/* Modal for register */}
            {isRegister && (
                <Register
                    isOpen={isRegister}
                    onClose={toggleModal}
                    setIsLoggedIn={setIsLoggedIn}
                />
            )}
        </div>
    );
};

export default ApplyJob;
