import axios from 'axios';
import { API_URLS } from '../constants';

// Fonction pour récupérer les données utilisateur
export const fetchUser = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(API_URLS.PROFILE, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.user) {
            return response.user;
        }
        throw new Error('User data not found');
    } catch (error) {
        throw new Error('Error fetching user: ' + error.message);
    }
};

// Fonction pour récupérer le profil en utilisant le token d'authentification
export const fetchProfile = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Token not found. Please log in.');
    }

    try {
        const response = await axios.get(API_URLS.PROFILE, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.data && response.data.user) {
            return response.data; // Retourne tout l'objet contenant user et profile
        }

        throw new Error('Profile data not found');
    } catch (error) {
        throw new Error('Error fetching profile: ' + (error.response?.data?.message || error.message));
    }
};

// Fonction pour sauvegarder ou mettre à jour le profil
export const saveProfile = async (userId, formData) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Token not found. Please log in.');
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    };

    try {
        const url = userId ? `${API_URLS.PROFILE}/${userId}` : API_URLS.PROFILE;
        const method = userId ? 'put' : 'post';

        const response = await axios[method](url, formData, config);
        if (response.data && response.data.message) {
            return response.data.message;
        }
        throw new Error('Unexpected response structure');
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error('Error saving profile: ' + errorMessage);
    }
};


// Met à jour le CV (résumé) d'un utilisateur.
export const updateCV = async (formData) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Token not found. Please log in.');
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    };

    try {
        const response = await axios.post(API_URLS.UPDATE_CV, formData, config);
        if (response.data && response.data.message) {
            return response.data.message;
        }
        throw new Error('Unexpected response structure');
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        throw new Error('Error updating CV: ' + errorMessage);
    }
};