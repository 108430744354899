import React, { useEffect, useState } from 'react';
import JobsService from '../../services/JobsService';
import Loading from '../../components/core/Loading';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import Select, { components } from 'react-select';
import '../../styles/Jobs.css';
import Error500 from '../error/Error500';

// Composant personnalisé pour l'option avec checkbox
const CheckboxOption = (props) => (
    <components.Option {...props}>
        <div className="flex items-center">
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null} // Pas besoin de gérer la sélection ici
                className="mr-3"
            />
            <label>{props.data.label}</label>
        </div>
    </components.Option>
);

const JobListings = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [jobTypes, setJobTypes] = useState([]);
    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [visibleOffers, setVisibleOffers] = useState(5);

    // Chargement des offres d'emploi
    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const { jobData } = await JobsService.getJobs();
                // Filtrer les offres valides
                const validOffers = jobData.filter((offer) =>
                    offer.title && offer.company && offer.type_contrat && offer.ville
                );
                setOffers(validOffers);
                setJobTypes([...new Set(validOffers.map((offer) => offer.type_contrat.name))]);
            } catch (err) {
                setError('Erreur lors de la récupération des offres');
            } finally {
                setLoading(false);
            }
        };
        fetchOffers();
    }, []);


    // Liste des villes uniques
    const uniqueCities = Array.from(
        new Set(offers.map((offer) => offer.ville.name))
    ).map((city) => ({
        value: city,
        label: `${city} (${offers.filter((offer) => offer.ville.name === city).length})`,
    }));

    // Filtrage des offres en fonction des critères sélectionnés
    const filteredOffers = offers.filter(
        (offer) =>
            (offer.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                offer.company.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (selectedJobTypes.length === 0 || selectedJobTypes.some(selected => selected.value === offer.type_contrat.name)) &&
            (selectedCities.length === 0 || selectedCities.some(selected => selected.value === offer.ville.name))
    );

    // Charger plus d'offres
    const loadMoreOffers = () => setVisibleOffers((prev) => prev + 3);

    // Gestion des changements dans les filtres de type de contrat
    const handleJobTypeChange = (selectedOptions) => {
        setSelectedJobTypes(selectedOptions || []);
    };

    // Gestion des changements dans les filtres de villes
    const handleCityChange = (selectedOptions) => {
        setSelectedCities(selectedOptions || []);
    };

    // Affichage du loading ou de l'erreur
    if (loading) return <Loading />;
    if (error) return <Error500 />;

    return (
        <div className="job-listings-container">
            <h1 className="section-title">Trouvez le job avec la culture qui vous correspond</h1>
            <header className="filter-header">
                <div className="filter-dropdown">
                    <input
                        type="text"
                        placeholder="Rechercher une offre..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-bar"
                    />
                </div>

                {/* Utilisation de react-select pour le filtre Type de Contrat */}
                <div className="filter-dropdown">
                    <Select
                        isMulti
                        options={jobTypes.map(type => ({ value: type, label: type }))}
                        value={selectedJobTypes}
                        onChange={handleJobTypeChange}
                        components={{ Option: CheckboxOption }} // Utilisation de notre composant personnalisé
                        className="react-select"
                        placeholder="Type de Contrat"
                    />
                </div>

                {/* Utilisation de react-select pour le filtre Villes */}
                <div className="filter-dropdown">
                    <Select
                        isMulti
                        options={uniqueCities}
                        value={selectedCities}
                        onChange={handleCityChange}
                        components={{ Option: CheckboxOption }} // Utilisation de notre composant personnalisé
                        className="react-select"
                        placeholder="Villes"
                    />
                </div>

                <button
                    className="clear-filters"
                    onClick={() => {
                        setSelectedJobTypes([]);
                        setSelectedCities([]);
                        setSearchTerm('');
                    }}
                >
                    Effacer les filtres
                </button>
            </header>

            <section className="jobs-section">
                <div className="jobs-list">
                    {filteredOffers.length === 0 ? (
                        <div className="no-jobs-message">
                            <p>Aucune offre ne correspond à vos critères de recherche.</p>
                        </div>
                    ) : (
                        filteredOffers.slice(0, visibleOffers).map((offer) => (
                            <Link to={`/jobs/apply/${offer.id}`} className="job-link" key={offer.id}>
                                <div className="job-card">
                                    <div className="job-header">
                                        <h2 className="job-title">{offer.title}</h2>
                                        <span className="job-company">{offer.company.name}</span>
                                    </div>
                                    <div className="job-details">
                                        <p style={{ display: 'inline', marginRight: '10px' }}>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} /> {offer.ville.name}
                                        </p>
                                        <p style={{ display: 'inline', marginRight: '10px' }}>
                                            <FontAwesomeIcon icon={faClock} /> Publié il y a {offer.posted_hours} heures
                                        </p>
                                        <p style={{ display: 'inline' }}>
                                            <FontAwesomeIcon icon={faBriefcase} /> {offer.type_contrat.name}
                                        </p>
                                    </div>
                                    <div className="job-description">
                                        <p>{offer.description.length > 200 ? `${offer.description.slice(0, 200)}...` : offer.description}</p>
                                        <p><strong>Date limite :</strong> {offer.last_date}</p>
                                    </div>

                                    <button
                                        className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition ${offer.hasApplied ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={offer.hasApplied}
                                    >
                                        {offer.hasApplied ? 'Déjà postulé' : 'Postuler'}
                                    </button>
                                </div>
                            </Link>
                        ))
                    )}
                    {filteredOffers.length > visibleOffers && (
                        <div className="load-more-container">
                            <button onClick={loadMoreOffers} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition">Voir Plus</button>
                        </div>
                    )}
                </div>
            </section>

        </div>
    );
};

export default JobListings;
