import React, { useState, useEffect, memo, useCallback } from 'react';
import { fetchCompaniesActifs } from '../../services/CompanieService';
import { URL, images } from '../../constants';
import Loading from '../../components/core/Loading';
import { useNavigate } from 'react-router-dom';
import '../../styles/Companies.css';
import { FaMapMarkerAlt, FaGlobe, FaInfoCircle } from 'react-icons/fa';
import Select, { components } from 'react-select';
import Error500 from '../error/Error500';

// Composant personnalisé pour l'option avec checkbox
const CheckboxOption = (props) => (
  <components.Option {...props}>
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null} // Pas besoin de gérer la sélection ici
        className="mr-3"
      />
      <label>{props.data.label}</label>
    </div>
  </components.Option>
);

const CompanyCard = memo(({ entreprise, onExpandToggle, isExpanded, handleJobClick }) => (
  <div
    className="entreprise-card"
    onClick={onExpandToggle}
    aria-expanded={isExpanded}
    tabIndex="0"
    role="button"
    aria-label={`Voir les détails de ${entreprise.name}`}
  >
    <img
      src={entreprise.banner ? `${URL}/${entreprise.banner}` : images.imageNotFound}
      alt={entreprise.banner ? `${entreprise.name} Banner` : 'Image non disponible'}
      className="entreprise-banner"
    />
    <div className="entreprise-header">
      <img
        src={entreprise.logo ? `${URL}/${entreprise.logo}` : images.imageNotFound}
        alt={entreprise.logo ? `${entreprise.logo} Logo` : 'Image non disponible'}
        className="entreprise-logo"
      />
      <h4 className="entreprise-name" onClick={handleJobClick}>{entreprise.name}</h4>
    </div>
    <div className="company-info-container">
      <p className="company-info">
        <FaMapMarkerAlt className="icon" /> {entreprise.address || 'Non spécifié'}
      </p>
      <p className="company-info">
        <FaGlobe className="icon" /> {entreprise.website || 'Non spécifiée'}
      </p>
      <p className="company-info">
        <strong>{entreprise.jobs_count > 0 ? `${entreprise.jobs_count} Offres` : 'Candidature spontanée !'}</strong>
      </p>
      <button className="follow-button">Suivre</button>
    </div>
    {isExpanded && (
      <div className="expanded-details">
        <p>
          <strong>Détails supplémentaires : </strong>
        </p>
        <p className="company-info">
          <FaInfoCircle className="icon" /> {entreprise.description || 'Non spécifiée'}
        </p>
      </div>
    )}
  </div>
));

const Companies = () => {
  const [entreprises, setEntreprises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedId, setExpandedId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEntreprises = async () => {
      setLoading(true);
      try {
        const data = await fetchCompaniesActifs();
        setEntreprises(data);
      } catch (error) {
        setError('Erreur lors de la récupération des entreprises');
      } finally {
        setLoading(false);
      }
    };

    fetchEntreprises();
  }, []);

  // Utilisation de useCallback pour éviter la recréation à chaque rendu
  const toggleExpand = useCallback((id) => {
    setExpandedId(prevId => (prevId === id ? null : id));
  }, []);

  const handleJobClick = useCallback((id) => {
    navigate(`/companies/${id}`);
  }, [navigate]);

  // Filtrer les entreprises selon le terme de recherche
  const filteredCompanies = entreprises.filter((entreprise) =>
    entreprise.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Mettre à jour le terme de recherche
  };

  if (loading) return <Loading />;
  if (error) return <Error500 />;

  return (
    <div className="companies-listings-container">
      <h1 className="section-title">Nouvelles entreprises à explorer</h1>
      <header className="filter-header">
        {/* <div className="filter-dropdown"> */}
        <input
          type="text"
          placeholder="Rechercher une entreprise..."
          value={searchTerm}
          onChange={handleSearchChange} // Mettre à jour la recherche
          className="search-input"
          aria-label="Recherche d'entreprises"
        />
        {/* </div> */}

        {/* Utilisation de react-select pour le filtre Type de Contrat */}
        {/* <div className="filter-dropdown">
          <Select
            isMulti
            components={{ Option: CheckboxOption }}
            className="react-select"
            placeholder="Secteur d'entreprise"
          />
        </div> */}

        <button
          className="clear-filters"
          onClick={() => {
            setSearchTerm('');
          }}
        >
          Effacer les filtres
        </button>
      </header>

      <section className="companies-section">

        <div className="container">
          <div className="companies-grid">
            {filteredCompanies.length === 0 ? (
              <div className="no-offers">
                <p>Aucune entreprise trouvée.</p>
              </div>
            ) : (
              filteredCompanies.map(entreprise => (
                <CompanyCard
                  key={entreprise.id}
                  entreprise={entreprise}
                  onExpandToggle={() => toggleExpand(entreprise.id)}
                  isExpanded={expandedId === entreprise.id}
                  handleJobClick={() => handleJobClick(entreprise.id)}
                />
              ))
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Companies;
