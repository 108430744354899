import React, { useState, useEffect } from 'react';
import Navbar from './components/menu/Navbar';
import Search from './components/Search';
import Footer from './components/core/Footer';
import Profile from './pages/Profile';
import Companies from './pages/companies/Companies';
import Jobs from './pages/jobs/Jobs';
import Media from './pages/media/Actualites';
import ApplyJob from './pages/ApplyJob';

import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Dashboard from './pages/office/Dashboard';
import NewCompany from './pages/office/NewCompany';

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import CompanyDetails from './components/companies/CompanyDetails';
import PublishJob from './pages/PublishJob';
import NotFound from './pages/error/NotFound';
import Error500 from './pages/error/Error500';
import SavedJobs from './components/jobs/SavedJobs';
import PrivateRoute from './components/PrivateRoute';

const AppContent = ({ isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/');
  };

  return (
    <div className="w-full">
      <div className="w-[90%] m-auto sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1536px]">
        <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} setIsLoggedIn={setIsLoggedIn} />
        <main>
          <Routes>
            <Route path="/" element={<Search />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/companies/:id" element={<CompanyDetails />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/jobs/apply/:id" element={<ApplyJob isLoggedIn={isLoggedIn} />} />
            <Route path="/media" element={<Media />} />

            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/register" element={<Register setIsLoggedIn={setIsLoggedIn} />} />

            <Route path="/dashboard" element={<PrivateRoute isLoggedIn={isLoggedIn} element={<Dashboard setIsLoggedIn={setIsLoggedIn} />} />} />
            <Route path="/profile" element={<PrivateRoute isLoggedIn={isLoggedIn} element={<Profile setIsLoggedIn={setIsLoggedIn} />} />} />
            <Route path="/publish-job" element={<PrivateRoute isLoggedIn={isLoggedIn} element={<PublishJob />} />} />
            <Route path="/new-company" element={<PrivateRoute isLoggedIn={isLoggedIn} element={<NewCompany />} />} />
            <Route path="/my-items/saved-jobs/" element={<PrivateRoute isLoggedIn={isLoggedIn} element={<SavedJobs />} />} />

            <Route path="/error-500" element={<Error500 />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </div>
  );
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Met à jour l'état en fonction de la présence du token
  }, []);

  return (
    <Router>
      <AppContent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
    </Router>
  );
}

export default App;

