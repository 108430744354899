import axios from 'axios';
import { URL_API } from '../constants';

const API_BASE_URL = `${URL_API}/actualites`;

const getActiveActualites = async () => {
    try {
        const response = await axios.get(API_BASE_URL);

        // Si la réponse contient un tableau de données (actualités)
        if (Array.isArray(response.data)) {
            return response.data;  // Renvoie directement les données (tableau d'actualités)
        } else {
            throw new Error('Erreur lors de la récupération des actualités : Format des données inattendu');
        }
    } catch (error) {
        console.error('Erreur dans ActualiteService:', error.message || error);
        // Retourner une valeur vide ou relancer l'erreur selon le cas
        throw error;
    }
};

export default {
    getActiveActualites
};
