import React, { Component } from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import JobsService from '../../services/JobsService';
import { URL, images } from '../../constants';
import Loading from '../core/Loading';
import Error500 from '../../pages/error/Error500';

export default class Jobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            loading: true,
            error: null,
        };
    }

    async componentDidMount() {
        await this.fetchJobs();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedVilles !== this.props.selectedVilles || prevProps.selectedContrats !== this.props.selectedContrats) {
            this.fetchJobs();
        }
    }

    fetchJobs = async () => {
        try {
            const { selectedVilles, selectedContrats } = this.props;
            const jobs = await JobsService.getJobs(selectedVilles, selectedContrats);
            this.setState({ jobs: Array.isArray(jobs.jobData) ? jobs.jobData : [], loading: false });
        } catch (error) {
            this.setState({ error: error.message, loading: false });
        }
    };

    getFilteredJobs = () => {
        const { jobs } = this.state;
        const { selectedVilles, selectedContrats } = this.props;

        return jobs.filter((job) => {
            const matchVille = selectedVilles.length === 0 || (job.ville && selectedVilles.includes(job.ville.id));
            const matchContrat = selectedContrats.length === 0 || (job.type_contrat && selectedContrats.includes(job.type_contrat.id));

            return matchVille && matchContrat;
        });
    };

    render() {
        const { loading, error } = this.state;
        const filteredJobs = this.getFilteredJobs();

        if (loading) return <Loading />;
        if (error) return <Error500 />;

        return (
            <section id="jobs" className="px-4 py-6">
                <div className="flex gap-4 justify-start flex-wrap items-start py-5 sm:py-10">
                    {filteredJobs.length === 0 ? (
                        <div className="no-offers">
                            <p>Aucune offre ne correspond à vos critères de recherche.</p>
                        </div>
                    ) :
                        filteredJobs.map((job) => (
                            <div
                                key={job.id}
                                className="flex flex-col justify-between bg-white rounded-md shadow-lg p-4 w-full sm:w-[300px] lg:w-[350px] mb-4"
                                style={{ minWidth: '250px', maxWidth: '350px', maxHeight: '450px' }}
                            >
                                <div className="upperpart flex justify-between items-center">
                                    <div className="titlecountry flex-grow">
                                        <p className="title font-bold text-xl">{job.title}</p>
                                        <p className="text-[#8b8b8b]">
                                            {(job.ville ? job.ville.name : '')} - {(job.type_contrat ? job.type_contrat.name : '')}
                                            {job.teletravail === 1 && <span> - Possibilité de télétravail</span>}
                                        </p>
                                    </div>
                                    <span className="text-[#8b8b8b]">
                                        <BiTimeFive className="inline mb-0.5 mr-1" />
                                        {new Date(job.last_date).toLocaleDateString()}
                                    </span>
                                </div>
                                <div className="lowerpart border-t-2 mt-4 flex-grow">
                                    <p className="mt-4 text-sm text-[#adaaaa]">
                                        {job.description.length > 80 ? `${job.description.substring(0, 80)}...` : job.description}
                                    </p>
                                    <div className="company flex items-center mt-4">
                                        <img
                                            className="rounded-full"
                                            src={job.company.logo ? `${URL}/${job.company.logo}` : images.imageNotFound}
                                            width={30}
                                            height={30}
                                            alt={job.company.name ? `${job.company.name} Logo` : 'Logo non disponible'}
                                        />
                                        <p className="ml-2 text-sm font-medium">{job.company.name || 'Entreprise inconnue'}</p>
                                    </div>
                                </div>

                                {job.hasApplied ? (
                                    <button className="border-2 font-medium rounded-lg p-2 w-full bg-gray-500 text-white cursor-not-allowed">
                                        Déjà postulé
                                    </button>
                                ) : (
                                    <Link to={`/jobs/apply/${job.id}`} className="text-blue-500 hover:underline mt-2 inline-block">
                                        <button className="border-2 font-medium rounded-lg p-2 w-full bg-blue-500 text-white hover:bg-blue-700">
                                            Postuler
                                        </button>
                                    </Link>
                                )}

                                {/* <button
                                    className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition ${job.hasApplied ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={job.hasApplied}
                                >
                                    {job.hasApplied ? 'Déjà postulé' : 'Postuler'}
                                </button> */}
                            </div>
                        ))
                    }
                </div>
            </section>
        );
    }
}
