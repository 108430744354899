import axios from 'axios';
import { URL_API } from '../constants';

const VILLE_URL = `${URL_API}/villes`;

export const fetchVillesActives = async () => {
    try {
        const response = await axios.get(`${VILLE_URL}`);
        return response.data;
    } catch (error) {
        throw new Error('Erreur lors du chargement des villes');
    }
};
