import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import SubmitCV from '../../components/SubmitCV';
import Alert from '../../components/Alert'; // Importer le composant d'alerte
import '../../styles/Dashboard.css';

const Dashboard = () => {
    const [isSubmitCVModalOpen, setIsSubmitCVModalOpen] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' }); // État pour l'alerte
    const modalRef = useRef(null);

    const toggleSubmitCVModal = () => {
        setIsSubmitCVModalOpen(prevState => !prevState);
    };

    const showAlert = (message, type) => {
        setAlert({ message, type });
        setTimeout(() => {
            setAlert({ message: '', type: '' }); // Réinitialiser l'alerte après 3 secondes
        }, 3000);
    };

    return (
        <section className="submit-area section-gap">
            <div className="container">
                <div className="row">
                    {/* Soumettre CV */}
                    <div className="col-lg-4 col-md-12 mb-4">
                        <div className="submit-left bg-blue-500 p-4 rounded">
                            <h4 className="text-white">Soumettez votre CV dès aujourd'hui</h4>
                            <p className="text-white">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed do eiusmod tempor incididunt ut labore.
                            </p>
                            <button onClick={toggleSubmitCVModal} className="primary-btn header-btn">
                                Soumettre votre CV
                            </button>
                        </div>
                    </div>

                    {/* Publier une offre */}
                    <div className="col-lg-4 col-md-12 mb-4">
                        <div className="submit-right bg-blue-500 p-4 rounded">
                            <h4 className="text-white">Publiez une nouvelle offre d'emploi maintenant !</h4>
                            <p className="text-white">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed do eiusmod tempor incididunt ut labore.
                            </p>
                            <Link to="/publish-job" className="primary-btn header-btn">
                                Publier une offre d'emploi
                            </Link>
                        </div>
                    </div>

                    {/* Devenir partenaire */}
                    <div className="col-lg-4 col-md-12 mb-4">
                        <div className="submit-right bg-blue-500 p-4 rounded">
                            <h4 className="text-white">Devenir partenaire</h4>
                            <p className="text-white">
                                Rejoignez-nous pour créer une société complète dans le site.
                            </p>
                            <Link to="/new-company" className="primary-btn header-btn">
                                Passe en société
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal pour soumettre le CV */}
            {isSubmitCVModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg w-full max-w-md mx-4" ref={modalRef}>
                        <h2 className="text-xl mb-4">Soumettre votre CV</h2>
                        <SubmitCV closeModal={toggleSubmitCVModal} showAlert={showAlert} />
                    </div>
                </div>
            )}

            {/* Afficher l'alerte si elle existe */}
            {alert.message && (
                <Alert
                    message={alert.message}
                    type={alert.type}
                    onClose={() => setAlert({ message: '', type: '' })}
                />
            )}
        </section>
    );
};

export default Dashboard;
