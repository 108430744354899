import React, { Component } from 'react';
import { GoLocation } from "react-icons/go";
import { AiOutlineSearch } from "react-icons/ai";
import Select from 'react-select';
import { components } from 'react-select';
import { fetchVillesActives } from '../services/VilleService';
import { fetchContratsActifs } from '../services/TypeContratService';
import Jobs from './jobs/Jobs';
import Error500 from '../pages/error/Error500';
import Loading from './core/Loading';

export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            villes: [],
            contrats: [],
            selectedVilles: [],
            selectedContrats: [],
            loading: true,
            error: null,
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        try {
            const [villes, contrats] = await Promise.all([fetchVillesActives(), fetchContratsActifs()]);
            this.setState({ villes, contrats, loading: false });
        } catch (error) {
            this.setState({ error: error.message, loading: false });
        }
    }

    handleSelectChange = (field, selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        this.setState({ [field]: selectedValues });
    };

    customOption = (props) => (
        <components.Option {...props}>
            <div className="flex items-center">
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null} // `react-select` gère la sélection
                    className="mr-3"
                />
                <label>{props.data.label}</label>
            </div>
        </components.Option>
    );

    render() {
        const { villes, contrats, selectedVilles, selectedContrats, loading, error } = this.state;

        const villesOptions = villes.map(ville => ({ value: ville.id, label: ville.name }));
        const contratsOptions = contrats.map(contrat => ({ value: contrat.id, label: contrat.name }));

        if (loading) return <Loading />;
        if (error) return <Error500 />;

        return (
            <div>
                <section className="Search rounded-lg">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <form>
                            <div className="flex flex-wrap justify-between">
                                {/* Section Villes */}
                                <div className="w-full sm:w-1/2 lg:w-1/2 lg:pr-4">
                                    <div className="flex items-center mb-2">
                                        <GoLocation className="inline mr-2 text-gray-500" />
                                        <label className="font-semibold">Villes :</label>
                                    </div>
                                    <Select
                                        isMulti
                                        options={villesOptions}
                                        value={villesOptions.filter(option => selectedVilles.includes(option.value))}
                                        onChange={(selectedOptions) => this.handleSelectChange('selectedVilles', selectedOptions)}
                                        components={{ Option: this.customOption }}
                                        className="w-full"
                                    />
                                </div>

                                {/* Section Contrats */}
                                <div className="w-full sm:w-1/2 lg:w-1/2 lg:pr-4">
                                    <div className="flex items-center mb-2">
                                        <AiOutlineSearch className="inline mr-2 text-gray-500" />
                                        <label className="font-semibold">Contrats :</label>
                                    </div>
                                    <Select
                                        isMulti
                                        options={contratsOptions}
                                        value={contratsOptions.filter(option => selectedContrats.includes(option.value))}
                                        onChange={(selectedOptions) => this.handleSelectChange('selectedContrats', selectedOptions)}
                                        components={{ Option: this.customOption }}
                                        className="w-full"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </section>

                {/* Affichage des offres d'emploi */}
                <Jobs selectedVilles={selectedVilles} selectedContrats={selectedContrats} />
            </div>
        );
    }
}
